<template>
  <item-card v-if="isVisible" :is-active="period.is_active">
    <!-- Menu -->
    <template v-slot:menu>
      <button class="button" @click="showSchedules()">
        <span>Lihat Jadwal</span>
        <fa-icon icon="list-ul"></fa-icon>
      </button>

      <button v-if="period.is_active" class="button" @click="showForm()">
        <span>Edit</span>
        <fa-icon icon="pencil-alt"></fa-icon>
      </button>
      <button
        v-if="period.is_active"
        class="button"
        @click="triggerDeletePeriod()"
      >
        <span>Hapus</span>
        <fa-icon icon="trash-alt"></fa-icon>
      </button>
    </template>

    <!-- Contents -->
    <section class="grid grid-cols-2 gap-4">
      <div>
        <span class="subtle-label">periode</span>
        <span class="content">{{ period.name }}</span>
      </div>
      <div>
        <span class="subtle-label">status</span>
        <span class="content">{{ period.is_active ? "Aktif" : "Lewat" }}</span>
      </div>
      <div>
        <span class="subtle-label">mulai</span>
        <span class="content">{{
          period.started_at | indonesian_date_format
        }}</span>
        <!-- hidden when is not active -->
      </div>
      <div>
        <span class="subtle-label">berakhir</span>
        <span class="content">{{
          period.ended_at | indonesian_date_format
        }}</span>
      </div>
      <div class="col-span-2">
        <button class="" @click="toggleAnnouncementPublication(period)">
          <fa-icon v-if="period.is_announced" icon="eye"></fa-icon>
          <fa-icon v-else icon="eye-slash"></fa-icon>
          <span class="ml-2">Daftar Kelulusan</span>
        </button>
      </div>
    </section>
  </item-card>
</template>


<script>
import { mapMutations, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      isVisible: true,
    };
  },
  props: {
    period: {
      required: true,
      type: Object,
    },
  },
  methods: {
    hide({ excluded_id }) {
      let isIncluded = this._uid !== excluded_id;

      if (isIncluded) {
        this.isVisible = false;
      }
    },
    show() {
      this.isVisible = true;
    },
    showForm() {
      this.hideMenu();
      this.SET_PERIOD_FORM_DATA(this.period);
      this.SHOW_FORM();
    },
    showSchedules() {
      this.hideMenu();
      this.hideOthers();
      this.SHOW_SCHEDULE_LIST();
      this.SET_SELECTED_PERIOD(this.period);
    },
    hideMenu() {
      EventBus.$emit("item-card.menu.hide");
    },
    hideOthers() {
      EventBus.$emit("period.item.hide", { excluded_id: this._uid });
    },
    async triggerDeletePeriod() {
      await this.deletePeriod(this.period);
      this.HIDE_SCHEDULE_LIST();

      // show all undeleted period
      EventBus.$emit("period.item.show");
    },
    async toggleAnnouncementPublication(item) {
      await this.togglePeriodAnnouncement(item);
    },
    ...mapActions("period", ["deletePeriod", "togglePeriodAnnouncement"]),
    ...mapMutations("period", [
      "SHOW_FORM",
      "SET_PERIOD_FORM_DATA",
      "SET_SELECTED_PERIOD",
    ]),
    ...mapMutations("schedule", {
      SHOW_SCHEDULE_LIST: "SHOW_LIST",
      HIDE_SCHEDULE_LIST: "HIDE_LIST",
    }),
  },
  mounted() {
    EventBus.$on("period.item.hide", this.hide);
    EventBus.$on("period.item.show", this.show);
  },
  name: "PeriodListItem",
  components: { ItemCard: () => import("@/components/Common/ItemCard.vue") },
};
</script>